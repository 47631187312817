#end_of_feed {
    position: fixed;
    bottom: -700px;
    width: 100%;
    background-color: #000;
    transition: 1000ms ease-in-out;
    opacity: 0;
    &.show {
        opacity: 1;
        bottom: 0;
        background: #000;
        // transform: translateY(-375px);
        #next_issue {
            bottom: 0px;
            opacity: 1;
        }
    }

    #spotlight {
        margin: 0 auto;
    }
}