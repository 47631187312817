$marvelRed: #f0141e;

header {
  span.mode-tag {
    background-color: $marvelRed;
    font-size: 10px;
    padding: 2px 5px;
    vertical-align: top;
    margin-left: 10px;
    font-family: TradeGothic;
    display: none;
    &.show {
      display: inline;
    }
  }
}

.preview-box {
  position: fixed;
  right: 7px;
  bottom: 88px;
  width: 270px;
  background-color: #fff;
  z-index: 1000;
  line-height: 1em;
  border: 1px solid #000;
  .text-container {
    padding: 19px;
  }
  p {
    font-size: 14px;
    margin: 0;
  }

  h3 {
    font-size: 17px;
    margin-bottom: 6px;
  }

  a {
    color: #000;
    text-decoration: underline;
  }
}

.preview-btn {
  border-top: 1px solid #ccc;
  height: 45px;
  line-height: 45px;
  float: left;
  font-size: 12px;
  text-align: center;
  cursor: pointer;

  &.learn-more {
    background-color: $marvelRed;
    color: #fff;
  }
  &.signin {
    border-right: 1px solid #ccc;
  }
}

.preview-buypage.last_page {
  position: relative;
  width: 100%;
  min-width: 0;
  max-width: 1024px;
  min-height: 0;
  padding: 0 0 50px;
  background: url("https://cdn.marvel.com/u/prod/marvel/images/mu/web/2021/reader-sell-bg.jpg") top center no-repeat;
  text-align: center;
  &#last_view {
    transform: translateY(-768px);
  }
  &#last_view.animate {
    transform: translateY(90px);
    min-height: 768px;
  }

  a {
    color: #fff;
    text-decoration: underline;
    &.btn {
      text-decoration: none;
    }
  }

  h1, h2, h3 {
    text-transform: uppercase;
    font-weight: normal;
  }

  h2, .cta-tag, .btn, .legaleese {
    margin: auto;
  }

  h1 {
    font-size: 3.1em;
    margin-top: 230px;
  }

  h2 {
    max-width: 400px;
    font-size: 1.8em;
    line-height: 1.1em;
    margin-top: 15px;
  }

  .cta-tag {
    font-size: 1.8em;
    margin-top: 15px;
  }

  .btn {
    display: block;
    width: 250px;
    height: 70px;
    margin-top: 25px;
  }

  .legaleese {
    max-width: 330px;
    font-size: 0.7em;
    margin-top: 15px;
    line-height: 1.4em;
  }

  .login-link {
    padding: 25px 25px 0 0;
    text-align: right;
    font-size: 0.9em;
    width: 100%;
    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}
