@import "config";

/* LAST VIEW INCLUDES SPOTLIGHT AND NEXT COMIC VIEW */
.last_page-wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
}
.last_page {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1024px;
  opacity: 0;
  color: #fff;
  transition-property: opacity, top, bottom;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  &.next-issue-container {
    top: -300px;
    margin: 50px auto 0;
    span.back {
      position: relative;
      top: -25px;
      left: 0;
      display: block;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
      font-family: TradeGothicBold;
      text-transform: uppercase;
      text-indent: 25px;
      text-align: left;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: -1px;
        width: 25px;
        height: 22px;
        background-image: url(../images/rocket_sprites.svg);
        background-position: -228px -40px;
        background-size: 400px 360px;
        display: block;
      }
    }
    &.animate {
      top: 0px;
    }
  }
  &.animate {
    opacity: 1;
  }
}
#next_issue {
  top: -110px;
  padding: 30px 0 30px 30px;
  margin: 0 auto;
  max-width: 1024px;
  color: #fff;
  background: #000;
  a {
    cursor: pointer;
  }
  h3 {
    color: $marvelRed;
    margin-bottom: 12px;
  }

  .thumbnail {
    padding-right: 20px;
    display: table-cell;
    float: none;
    img {
      width: 168px;
      height: 252px;
    }
  }

  /* NEXT BOOK VIEW */
  .content {
    display: table-cell;
    float: none;
    vertical-align: top;
    max-width: none;
    width: 100%;

    aside {
      margin-left: -20px;
      background: #fff;
      padding: 12px 20px;
      width: calc(100% + 20px);
      padding-right: 0;
      position: relative;

      h2 {
        color: #000;
        display: inline-block;
        width: 85%;
        text-transform: uppercase;
        font-size: 24px;
        line-height: 23px;
      }

      a {
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        height: 102%;
        padding: 0;
        background-color: $marvelRed;
        text-align: center;
        color: #fff;
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        border: none;
        outline: none;
        justify-content: center;
        align-items: center;

        span {
          height: 27px;
          width: 16px;
          text-align: center;
          vertical-align: middle;
          display: table-cell;
          background-position: -526px -98px;
          background-image: url(../images/rocket_sprites.svg);
        }
      }
    }
  }

  .defaultView {
    background-color: transparent;
    background-image: url(../images/marvel_mosiac.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

#spotlight {
  top: -110px;
  padding: 20px 0 0;
  margin: 20px auto 0;
  max-width: 1024px;
  background: #000;
  > h3 {
    padding: 0 30px 10px;
    color: #fff;
    font-size: 28px;
  }
  h6 {
    padding: 25px 30px 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .series_title {
    padding: 0 30px 25px;
    color: #fff;
    font-size: 28px;
    font-family: "TradeGothicBold";
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
  }
  &.animate {
    opacity: 1;
    top: 0px;
  }

  &.animateWithoutNextComic {
    opacity: 1;
    padding: 30px;
    top: 80px;
    max-height: none;
    overflow: hidden;
    padding-right: 0px;
  }
  .spotlight_container {
    padding: 0 30px 30px;
    .discover_container {
      background-color: $marvelGray;

      h6 {
        font-size: 14px;
        line-height: 42px;
        text-align: center;
        color: $marvelRed;
        text-transform: uppercase;
      }

      h3 {
        font-size: 28px;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .bkg_spotlight_container {
      h6 {
        font-size: 14px;
        text-align: center;
        color: $marvelRed;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h3 {
        font-size: 28px;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: $bp-med) {
          text-align: left;
          font-size: 28px;
        }
      }
    }

    .img_sl_cont {
      position: relative;
      border: 1px gray solid;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .inner_sl_cont {
      .slider_cont {
        position: relative;
        background-color: #000000;

        .slick-slider {
          &.two {
            padding: 0px 56px;
          }

          &:after {
            content: "";
            height: 100%;
            right: 0;
            position: absolute;
            top: 0;
            width: 50px;
            background: linear-gradient(
              to left,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 0) 100%
            );
          }

          &::before {
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 50px;
            z-index: 5;
            background: linear-gradient(
              to right,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 0) 100%
            );
          }

          .slick-list {
            overflow: hidden;
          }
          .slick-track {
            display: flex;
          }
          .slick-slide {
            padding: 15px 10px;
            float: left;
            color: #fff !important;

            aside {
              float: left;
              width: 73%;
              overflow: hidden;
              height: 57px;
            }

            h2 {
              font-size: 16px;
            }

            h4 {
              font-size: 12px;
            }

            span.number {
              float: left;
              background: $marvelGray;
              color: #fff;
              font-family: TradeGothicBold;
              font-size: 14px;
              padding: 3px;
              margin: 5px 8px 5px 0px;
              width: 25px;
              height: 25px;
              text-align: center;
            }
          }
        }
      }
    }

    /* Arrows */
    .slick-prev,
    .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: calc(50% - 44px);
      display: block;
      width: 52px;
      height: 88px;
      margin-top: -10px;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background-color: #000;
      background-image: url(../images/rocket_sprites.svg);
      z-index: 50;

      &::before {
        content: "";
      }
    }

    .slick-prev {
      left: 0px;
      border-right: 1px solid #3b3b3b;
      border-top: 1px solid #3b3b3b;
      border-bottom: 1px solid #3b3b3b;
      background-position: -464px -68px;
    }

    .slick-next {
      right: 0px;
      border-left: 1px solid #3b3b3b;
      border-top: 1px solid #3b3b3b;
      border-bottom: 1px solid #3b3b3b;
      background-position: -505px -68px;
    }
  }
}

/* Responsive End of Page */
//x-large 1024+
@media (min-width: $bp-large) {
  #next_issue {
    .content aside a {
      width: 15%;
      font-size: 18px;
      background-image: none;
      span {
        top: 24px;
        left: 22px;
        position: relative;
        text-align: center;
        transform: translateY(-50%);
        font-family: "tradeGothicBold";
        background-image: none;
        height: 24px;
        width: auto;
      }
    }
  }
}
//large 1023-800
@media (max-width: $bp-large) {
  #next_issue {
    .content aside a span {
      display: inline-block;
      position: relative;
      margin-top: 35%;
      transform: translateY(-50%);
    }
  }
}
//medium 800-571
@media (max-width: $bp-med) {
  #next_issue {
    .content aside {
      a span {
        height: 30px;
      }
      h2 {
        width: 72%;
        margin-left: -5px;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  #spotlight {
    .series_title {
      padding: 0 30px 10px;
      text-align: left;
    }
    .spotlight_container {
      .bkg_spotlight_container {
        h6 {
          text-align: left;
          font-size: 14px;
        }
        h3 {
          padding: 25px 30px;
          text-align: left;
          font-size: 28px;
        }

        .inner_sl_cont {
          h3 {
            @media (max-width: $bp-med) {
              font-size: 28px;
              line-height: 6px;
            }
          }
          .slider_cont {
            .slick-slider .slick-slide {
              aside {
                height: 48px;
                width: 64%;
              }
              h2 {
                font-size: 13px;
              }
              h4 {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}
//small 571-all
@media (max-width: $bp-small) {
  &#last_view.animate #next_issue {
    padding: 30px 0;
    .issue-heading {
      margin-left: -36px;
      font-size: 15px;
    }
    .thumbnail {
      display: none;
    }
    .content {
      display: block;
      aside {
        margin-left: -30px;
        width: 100%;
      }
      article {
        margin: 25px 0 0;
      }
      a span {
        height: 32px;
      }
    }
    &#last_view {
      &.animate {
        height: auto;
      }
    }
  }
}
/* Responsive End of Page */

/* DEFAULT NO NEXT BOOK VIEW */

/* DEFAULT NO NEXT BOOK VIEW */
.wrapper {
  position: relative;
  overflow: hidden;
  max-height: 455px;
  height: 100%;
  @media (max-width: 728px) {
    overflow: unset;
  }
}
.dontStopNow-img {
  position: absolute;
  height: 100%;
  width: unset;
  top: 0px;
  right: 0px;
}
.dontStopNow {
  position: relative;
  height: 100%;
  padding: 30px;
  background: inherit;
  color: #fff;

  h1 {
    font-size: 3em;
    text-transform: uppercase;
  }
  p {
    margin-top: 10px;
    font-size: 1.1em;
  }
}
.defaultView {
  top: -110px;
  padding: 0;
  background-color: transparent;
  background-image: url(../images/marvel_mosiac.png);
  background-repeat: no-repeat;
  background-size: cover;
  .btn {
    span {
      font-size: 1.2em;
      padding: 10px 50px;
    }
    &::before {
      left: -2px;
      height: 67px;
      width: 17px;
    }
    &:after {
      right: -4px;
      height: 67px;
      width: 18px;
    }
  }
  .dontStopNow {
    height: 100%;
    width: 48%;
    background: inherit;
    padding: 30px;
    position: relative;

    h1 {
      margin-top: 82px;
      font-size: 3em;
      text-transform: uppercase;
    }
    p {
      margin-top: 10px;
      font-size: 1.1em;
    }
  }
  .mask {
    position: absolute;
    top: 0;
    left: -50px;
    width: 55%;
    min-height: 375px;
    background-color: #000;
    opacity: 0.91;
    transform: skewX(-12deg);
  }
  &.animate {
    top: 0px;
  }
}
.freePromoView {
  top: -110px;
  padding: 0;

  .btn {
    span {
      font-size: 1.2em;
      padding: 10px 50px;
    }
    &::before {
      left: -2px;
      height: 67px;
      width: 17px;
    }
    &:after {
      right: -4px;
      height: 67px;
      width: 18px;
    }
  }
  .dontStopNow {
    width: 48%;
    background: unset;
    
    h1 {
      font-size: 3em;
      text-transform: uppercase;
    }
    p {
      margin-top: 10px;
      font-size: 1.1em;
    }
    a {
      margin: 0 0 0 10px;
    }
  }
  .mask {
    position: absolute;
    top: 0;
    left: -50px;
    width: 55%;
    height: 100%;
    min-height: 375px;
    background-color: #000;
    opacity: 0.91;
    transform: skewX(-10.5deg);
  }
  &.animate {
    top: 0px;
  }
}
.dontStopNow-text {
  font-size: 35px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  font-family: "TradeGothicBold";
}
.dontStopNowLegal {
  padding: 10px 10px 0 0;
  display: block;
  text-align: right;
  font-size: 12px;
  a {
    font-weight: 800;
    color: #fff;
  }
}
//large 571-800
@media (max-width: $bp-large) {
  .defaultView {
    .dontStopNow {
      width: 69%;
      &:after {
        left: 1%;
      }
    }
    .mask {
      width: 75%;
    }
  }
}
@media (max-width: $bp-med) {
  .defaultView {
    &:after {
      content: "";
      width: 100%;
      background-color: #000;
      opacity: 0.91;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0%;
      z-index: -1;
      transform: none;
    }
    .dontStopNow {
      width: 100%;
      h1 {
        margin-top: 2px;
        font-size: 2.5em;
      }
      a {
        float: none;
        display: table-cell;
        vertical-align: middle;
      }
    }
    .discoverBtn-Cont {
      display: table;
    }
    .btn {
      &::before {
        left: 219px;
      }
      &:after {
        right: -4px;
      }
      span {
        margin-left: auto;
        width: 224px;
      }
    }
    .mask {
      width: 100%;
      left: 0;
      transform: none;
    }
  }
}
@media (max-width: 728px) {
  #next_issue {
    .content aside {
      padding-right: 0px;
      a  {
        width: 45px;
        span {
          height: 23px;
        }
      }
      h2 {
        margin-left: -15px;
        font-size: 15px;
      }
    }
  }
  .freePromoView {
    .wrapper {
      margin-top: 20px;
      position: relative;
      background-position: right;
      max-height: unset;
    }
    .dontStopNow-img {
      position: relative;
      width: 100%;
      height: unset;
    }

    .mask {
      display: none;
    }
    .dontStopNow-wrapper {
      display: flex;
      min-height: 250px;
      background-color: #000;
      .dontStopNow { 
        width: 100%;
        height: unset;
        .discoverBtn-Cont {
          display: flex;
          justify-content: center;
        }
      }
    }
    .dontStopNowLegal {
      padding-top: 10px;
      text-align: center;
    }
  }
  .defaultView {
    .btn {
      &::before {
        left: -3px;
      }
      &:after {
        right: -4px;
      }
      span {
        width: 220px;
      }
    }
  }
}
