div#page {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  user-select: none;
  &.ajax-loading-animation {
    background-image: url(../images/ajax-loader.gif);
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.stripes {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAH0lEQVQIW2P4//+/JhAzIGMUDhRrYgigq4QbgyEAwgChmkuDn1MilAAAAABJRU5ErkJggg==");
    background-repeat: repeat;
    scroll-behavior: smooth;
    overflow: hidden;
    transition: width 2000ms ease-out, height 2000ms ease-out;
  }

  &.zoomish {
    padding-right: 20px; /* removes vertical scroll bar */
    padding-bottom: 20px; /* removes horizontal scroll bar */
    overflow: auto;

    &.grabbing {
      transition: none;
      scroll-behavior: auto;
      &:hover {
        cursor: grabbing;
      }
      .svg-el {
        transition: none;
      }
    }

    &:hover {
      cursor: grab;
    }
  }
  .pageImage {
    pointer-events: none;
  }
}

.svg-el {
  position: absolute;
  top: 0;
  background-color: #000;
  min-width: 100%;
  transition: width 500ms, height 500ms;
  .stripes & {
    background-color: transparent !important;
  }
}

