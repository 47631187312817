#info_tooltips {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;

  &._inView {
    display: block;
  }
  .toolTip_wrap {
    position: relative;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  .toolTip {
    $color: #eee;
    position: absolute;
    padding: 7px;
    margin: auto;
    max-width: 180px;
    background: $color;
    color: #000;
    font-size: 13px;
    font-family: sans-serif;
    line-height: 1.3em;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 15px 15px;
      border-color: transparent transparent $color transparent;
    }

    &.showInfo {
      top: 58px;
      right: 3px;
      width: 47px;
      &::before {
        top: -15px;
        right: 4px;
      }
    }
    &.help {
      top: 58px;
      right: 52px;
      width: 60px;
      &::before {
        top: -15px;
        right: 0px;
      }
    }
    &.left_arrow {
      bottom: 80px;
      right: calc(50% + 145px);
      &::before {
        bottom: -15px;
        right: 0px;
        transform: rotate(180deg);
      }
    }
    &.right_arrow {
      bottom: 80px;
      left: calc(50% + 150px);
      &::before {
        bottom: -15px;
        left: 0px;
        transform: rotate(180deg);
      }
    }
    &.allPages {
      top: 58px;
      left: 15px;
      width: 60px;
      &::before {
        top: -15px;
        left: 0px;
      }
    }
    &.btn-screen {
      bottom: 80px;
      left: calc(50% + 40px);
      &::before {
        bottom: -15px;
        left: 0px;
        transform: rotate(180deg);
      }
    }
    &.btn-zoom {
      bottom: 80px;
      right: calc(50% + -25px);
      width: 50px;
      &::before {
        bottom: -15px;
        left: 0px;
        transform: rotate(180deg);
      }
    }
    &.btn-panel {
      bottom: 80px;
      right: calc(50% + 50px);
      &::before {
        bottom: -15px;
        right: 0px;
        transform: rotate(180deg);
      }
      &.full {
        width: 70px;
      }
    }

    //Specific markup within
    ._color {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      background: #282828;
    }
    ._red {
      background: #ec1329;
    }
  }
  button._dismiss {
    -webkit-transform: skewX(-11deg);
    -moz-transform: skewX(-11deg);
    transform: skewX(-11deg);
    text-transform: uppercase;
    background: #ec132a;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 900;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 80px);
    cursor: pointer;
    &:hover {
      background: #fd243b;
    }
    span {
      -webkit-transform: skewX(11deg);
      -moz-transform: skewX(11deg);
      transform: skewX(11deg);
      display: block;
      &::before {
        content: attr(data-translation-txt-keep-reading);
      }
    }
  }
}
