@import "config";

.rocket-dpop {
  width: 100%;
  height: calc(100vh - 50px);
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  margin: auto;
  position: relative;
  background: transparent;
  top: 55px !important;

  .thumbs-wrap {
    max-width: 995px;
    margin: 0 auto;

    $rowSizeList: 999px, 773px, 562px, 364px, 181px;
    $ind: 1;
    @each $size in $rowSizeList {
      $ind: $ind + 1;
      @if ($ind < 6) {
        @media (max-width: $size) {
          width: nth($rowSizeList, $ind);
        }
      }
    }
  }

  .thumbs {
    width: $thumbWidth;
    height: $thumbHeight;
    display: inline-block;
    margin: 1.5% 1.5%;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    background-color: #000;
    &.loading {
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("../images/preloader.gif");
      span {
        display: none;
      }
    }
    &.locked {
      cursor: default;
      .img-div {
        background-image: url("../images/preview_membersonly-tn.jpg");
      }
    }
    .img-div {
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &.activePage .img-div {
      border-style: solid;
      border-color: $marvelRed;
      border-width: 7px 5px;
    }

    span {
      display: block;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      background: rgba(0, 0, 0, 0.75);
      font-weight: bold;
      text-align: center;
      font-size: 30px;
      box-sizing: border-box;
      padding-top: 10px;
      text-align: center;
    }
  }
}

//DPOP OVERRIDE YO
#overlayRelativer {
  ._DPOP_mask {
    &.fullPageView {
      background-color: rgba(0, 0, 0, 0.95);
    }
  }

  .aPawInMyFace {
    top: 20%;
    background: #000;
    max-width: 720px;
    padding: 30px;
    //position: relative !important;

    &::before {
      position: absolute;
      height: 5px;
      background: $marvelRed;
      width: 100%;
      display: block;
      top: 0;
      content: "";
      left: 0;
    }
  }

  .dPopClose {
    top: 5px;
    background: $marvelRed;

    .icon-close {
      width: 20px;
      height: 20px;
      background-image: url(../images/rocket_sprites.svg);
      background-position: -608px -101px;
      display: block;
      &:after {
        display: inline-block;
        content: "";
      }
    }
  }
  @media (max-width: $bp-med) {
    .bringDPopUpIn {
      margin-top: 80px;
    }
    .aPawInMyFace {
      position: relative !important;
    }

    .thumbnail {
      border: 2px dashed blue;
      display: none;
    }
    .content {
      max-width: none;
      margin-left: 0px;
      article {
        margin-left: 0px;
        p.desc {
          max-height: none;
        }
      }
    }
  }
}
