.partner-logo {
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
  img {
    height: 50px;
    width: 200px;
  }
  &.show-logo {
    display: block !important; //Override inline
  }
}

.clickable-page {
  .is-clickable {
    &.clickable-area {
      cursor: pointer;
    }
  }
}
