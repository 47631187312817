.error-page {
  color: #fff;
  font-family: TradeGothicBold;

  header > div {
    background: #111;
  }

  .message-container {
    background: #140001;
    margin: 90px auto 0;
    max-width: 940px;
    img {
      padding: 30px;
      width: auto;
      vertical-align: middle;
    }
    .message {
      display: inline-block;
      max-width: 669px;
      vertical-align: middle;

      .headline {
        background: #fff;
        color: #000;
        text-transform: uppercase;
        padding: 20px;
        margin: 0 0 10px;
        font-size: 2em;
      }
      .words {
        padding: 0 20px;
        font-family: TradeGothic;
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 939px) {
    .message-container .message {
      max-width: none;
      margin-bottom: 30px;
    }
  }
}
