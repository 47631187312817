@import "config";

header {
  position: fixed;
  background: #000;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 15px 0px 0px;
  max-height: 120px;
  overflow-y: hidden;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  top: 0;

  & > span {
    display: block;
    cursor: pointer;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    top: -12px;

    &:first-child {
      float: left;
      margin-left: 10px;
      background-position: -384px $whiteBackgroundPosition;

      &.active {
        text-indent: 0;
        overflow: hidden;
        background: none;
        text-transform: uppercase;
        padding-left: 10px;
        width: auto;
        font-size: 12px;
        margin-left: 0;
        top: 3px;
        text-indent: 8px;
        &::before {
          background-position: -38px $whiteBackgroundPosition;
          position: absolute;
          content: "";
          background-image: url(../images/rocket_sprites.svg);
          width: 20px;
          height: 33px;
          left: -3px;
          top: -15px;
          background-position: -7px -19px;
          background-size: 300px 300px;
        }
      }
      & ~ span {
        float: right;
        margin-right: 10px;
        background-position: -425px $whiteBackgroundPosition;
      }
    }

    &.icon {
      &.help {
        display: block !important; //Override inline
        background-position: -555px $whiteBackgroundPosition;
      }
    }
  }

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 18px;
    padding: 0;
    text-align: center;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
    span.title {
      font-family: "TradeGothicBold";
    }
  }

  div.progress-bar {
    width: 100%;
    height: 5px;
    position: relative;
    span {
      display: block;
      cursor: default;
      float: none !important;
      margin: 0 !important;
      height: 100%;
      width: 0%;
      position: absolute;
      transition: width 0.4s ease-in-out;
      &.progress {
        background-color: $marvelRed;
      }
      &.loading {
        background-color: #222222;
      }
    }
  }
  
  &.infinite-comic {
    .allPages {
      display: none;
    }
  }
}

.fullPageHeader {
  @media (max-width: $bp-small) {
    height: 58px;
  }
}
