.vertical-reader {
  .panel-feed {
    max-width: 800px;
    margin: auto;
  }

  .panel-image {
    width: 100%;
    vertical-align: top;
    border: 0;
    &:last-child {
      margin: 0 0 25vh;
    }
  }

  #spotlight {
    .series_title {
      text-align: center;
      font-family: TradeGothicBold;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 23px;
    }
  }

  #info_tooltips {
    .toolTip {
      &.left_arrow,
      &.right_arrow,
      &.allPages,
      &.btn-zoom,
      &.btn-panel {
        display: none;
      }

      &.btn-screen {
        left: calc(50% + -15px);
      }
    }
  }

  .preview-box {
    top: 70px;
    bottom: unset;
  }
}

@media (max-width: 800px) {
  .vertical-reader {
    #spotlight {
      h6, .series_title {
        margin: 0 0 0 15px;
        text-align: left;
      }
    }
  }
}
