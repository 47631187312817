@import "config";

* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

/* iPad styles */
html[data-useragent*='iPad'] {
  .rocket-dpop, .last_page {
    width: 768px;
  }
  .rocket-dpop._DPOP_window {
    max-width: 560px;
  }
  .last_page .spotlight_container {
    .slider {
      width: 360px;
      aside h2 {
        font-size: 12px;
      }
      .book {
        width: 100px;
      }
    }
    .discover_container {
      width: 370px;
      height: 300px;
    }
  }

}

html[data-useragent*='iPad'], html[data-useragent*='iPhone'] {
  .rocket-dpop, .last_page {
    width: 100%;
  }
  footer {
    position: fixed;
  }
  li.btn-screen {
    display: none !important;
  }
}

/* iFrame styles */
html[data-iframed] {

  /* Maybe you don't need to reset the footer to absolute in the iPad iframe?
      Can't test it on the kids' site now, so just in case... -roshow */
  &[data-useragent*='iPad'] {
    footer {
      position: absolute;
    }
  }

  &, body {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  font-family: "TradeGothicBold";
}

body {
  background-color: #000;
  font-family: "TradeGothic", sans-serif !important;
  color: #333;
  &.overflow-hidden {
    overflow: hidden;
  }
}

img {
  width: 100%;
  height: auto;
}

button {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
}

.hide {
  visibility: hidden;
}

.displayNone {
  display: none
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

.icon {
  background-image: url(../images/rocket_sprites.svg);
  width: 34px;
  height: 40px;
  font-family: 'tradeGothicBold';
  caret-color: transparent;
}

.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px;
  h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
  }
}

.img-skrim {
  position: absolute;
  width: 100%;
  height: 175px;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  @media (max-width: $bp-med) {
    height: 60px;
  }
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* INFO BOX */
.thumbnail {
  display: inline;
  img {
    width: 220px;
    height: 330px;
  }
}

.content {
  max-width: 400px;
  display: inline-block;
  margin-left: 30px;
  vertical-align: top;

  h1 {
    margin: 0;
    font-size: 32px;
    text-transform: uppercase;
  }

  article {
    margin-top: 25px;
    p.desc {
      max-height: 150px;
      overflow-y: auto;
    }
    // @media (max-width: $bp-small) {
    //   margin-left: -36px;
    // }
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    /*  font-family: 'tradeGothic' */

    span {
      font-weight: bold;
      text-transform: capitalize;
      /*  font-family: 'tradeGothicBold' */
    }
  }
}

/* header and footer slideAway animations but not for kidsbook route */
body:not(.kidsbook) {
  .slideAway {
    max-height: 0px; /* approximate max height */
    padding: 0 !important;
  }
}

#book_info {
  display: none;
}

/* BUTTONS */
.btn {
  border: none;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  padding: 0 7px;
  line-height: 47px;
  font-size: 15px;
  color: #fff !important;
  font-family: TradeGothicBold;
  overflow: visible;
  background: transparent;
  margin: 10px 1px;
  outline: 0 none;

  &::before {
    background-color: $marvelRed;
    left: 3px;
    content: '';
    height: 47px;
    position: absolute;
    transform: skew(-11deg, 0deg);
    width: 11px;
    top: 0;
  }

  &:after {
    right: 3px;
    content: '';
    height: 47px;
    position: absolute;
    transform: skew(-11deg, 0deg);
    width: 11px;
    background-color: $marvelRed;
    top: 0;
  }

  span {
    background-color: $marvelRed;
    display: block;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
  }
}
