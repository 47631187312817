/* =============================================================================
   Overlays
   ========================================================================== */
#overlayRelativer,
._DPOP_mask {
  top: 0;
  left: 0;
  width: 100%;
}

#overlayRelativer {
  position: absolute;
  height: 0;
  text-align: center;
  overflow: visible;
}

._DPOP {
  &_mask {
    position: fixed;
    height: 100%;
    display: none;
    background-color: rgba(0, 0, 0, .8);
  }

  &_window {
    margin: 0 auto;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    position: relative;
    display: block;
    -webkit-backface-visibility: hidden;
    box-shadow: 0 0 11px #000;
    text-align: left;
  }
  &_hasMask {
    height: 100%;
    &._DPOP_mask {
      top: 50px;
      height: 100vh;
      width: 100%;
      display: block;
    }
  }
}

.dPop_isFixed {
  width: 100%;
  position: fixed;
}

/***************************
WINDOW STATES & ANIMATIONS
****************************/
.bringDPopUpIn ._DPOP_window {
  display: block;
}

.bringDPopUpOut ._DPOP_window {
  display: none;
}

/***************************
CORE DPOP COLORS AND THEME
***************************/
.dPopClose {
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  right: 0;
  top: -19px;
  background: #2B2B2B;
  text-align: center;
  line-height: 1em;
  .icon-close {
    &:after {
      content: 'x';
    }
  }
}

._DPOP_window {
  background: #2B2B2B;
  color: #fff;
  .dPopClose {
    .icon-close {
      color: #fff;
      font-size: 26px;
      font-weight: bold;
      display: inline;
    }
  }
}
