@import "config";

footer {
  position: fixed;
  max-height: 75px;
  background: #000;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  bottom: 0;
  overflow-y: hidden;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  text-align: center;
  nav {
    max-width: 350px;
    margin: 15px auto;
    height: 51px;
    display: inline-flex;
    justify-content: space-between;
  }
  .arrow {
    display: block;
    height: 100%;
    width: 35px;
    cursor: pointer;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    &:first-child {
      float: left;
      background-position: -38px $defaultColorBackgroundPosition;
      &:hover {
        background-position: -38px $whiteBackgroundPosition;
      }
      &:active {
        background-position: -38px -208px;
      }
    }
    &:last-of-type {
      background-position: -68px $defaultColorBackgroundPosition;

      &:hover {
        background-position: -68px $whiteBackgroundPosition;
      }
      &:active {
        background-position: -68px -208px;
      }
    }
    &.icon {
      height: 51px;
      display: inline-block;
    }
  }
  .tools {
    width: 225px;
    height: 100%;
    display: flex;
    justify-content: space-evenly;

    border-left: 1px solid #333333;
    border-right: 1px solid #333333;

    div {
      display: inline-block;
      width: 34px;
      height: 40px;
      cursor: pointer;
      text-indent: 100%;
      white-space: nowrap;
      // overflow: hidden;

      &.btn-panel {
        background-position: -207px $defaultColorBackgroundPosition;
        &:hover {
          background-position: -207px $whiteBackgroundPosition;
        }
        &.disable,
        &.disable:hover {
          background-position: -207px $defaultColorBackgroundPosition;
          opacity: 0.2;
          cursor: default;
        }
      }

      &.btn-zoom {
        background-position: -341px $defaultColorBackgroundPosition;
        &:hover {
          background-position: -341px $whiteBackgroundPosition;
        }
      }

      &.btn-screen {
        background-position: -120px $defaultColorBackgroundPosition;
        width: 37px;
        &:hover {
          background-position: -120px $whiteBackgroundPosition;
        }
      }
    }
  }
  &.infinite-comic {
    nav {
      max-width: 220px;
    }
    .tools {
      width: 95px;
    }
    .arrow,
    .btn-panel,
    .btn-zoom {
      display: none;
    }
  }
  &.panel-on {
    div.btn-zoom {
      background-position: -341px -148px;
      cursor: default;
      &:hover {
        background-position: -341px -148px;
      }
    }
    div.btn-panel {
      background-position: -251px $defaultColorBackgroundPosition;
      &:hover {
        background-position: -251px $whiteBackgroundPosition;
      }
    }
  }
  &.zoom-on {
    div.btn-zoom {
      background-position: -297px $defaultColorBackgroundPosition;
      &:hover {
        background-position: -297px $whiteBackgroundPosition;
      }
    }
  }
  &.fullscreen-on {
    div.btn-screen {
      background-position: -163px $defaultColorBackgroundPosition;
      &:hover {
        background-position: -163px $whiteBackgroundPosition;
      }
    }
  }
  &.disable-next {
    span {
      &:last-of-type {
        float: right;
        background-position: -68px -148px;
        &:active {
          background-position: -68px -148px;
        }
      }
    }
  }
}
