.kidsbook {
  #last_view {
    display: none; //This view is not needed in our route. If reenabled in future we'd may want to work out the contents to stay within the max-width
  }
  div#page {
    //make up for missing icons
    top: 50px;
    height: calc(100% - 125px);
  }
  .icon {
    background-image: url(../images/baby_rocket_sprites.svg);
  }
}
