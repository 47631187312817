.cd-section {
  height: 100vh;
  position: fixed;
  background: transparent;
  width: 100%;

  &.active {
    .cd-half-block {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);

    }
  }

  &.background {

    .cd-half-block {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAH0lEQVQIW2P4//+/JhAzIGMUDhRrYgigq4QbgyEAwgChmkuDn1MilAAAAABJRU5ErkJggg==");
    }
  }

  .cd-half-block {
    color: #263b40;
    height: 100vh !important;
    width: 50%;
    position: absolute;
    top: 0;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: ease;
    background: #000;
  }

  .cd-block {
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
  }
}

.cd-block, .cd-half-block {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  //-webkit-backface-visibility: hidden;
  //backface-visibility: hidden;
  //box-shadow:  5px 0 19px -4px rgba(0, 0, 0, 0.69);

}

.cd-block {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  height: 100vh;

  .left {
    left: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  
  .right {
    right: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}